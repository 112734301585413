import { useAuth, Auth } from '@maersk-global/apmt-flow-keycloak';
import {
    EventStreamContentType,
    EventSourceMessage,
    fetchEventSource,
} from '@maersk-global/fetch-event-source';
import Cookies from 'js-cookie';
import { Codec, Either, exactly, GetType, oneOf } from 'purify-ts';
import { useCallback, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/shallow';

import { CookieNames } from '@/constants/cookies';
import { routes } from '@/routes/routes';
import {
    chePositionsChangedDecoder,
    terminalTruckPositionsChangedDecoder,
    useTruckPositionsStore,
} from '@/store';
import { useAndonsStore } from '@/store/andonsStore';
import { useChePositionsStore } from '@/store/chePositions';
import { ConnectionWarningStore, useConnectionWarningStore } from '@/store/connectionWarningStore';
import { useCraneActivityStore } from '@/store/craneActivity';
import { useDelayCodeStore } from '@/store/delayCodesStore';
import { useQuayCraneStore } from '@/store/quayCranesStore';
import { useTruckStatusStore } from '@/store/truckStatus';
import { useYardStore } from '@/store/yardStore';
import {
    moveInstructionV2EventDecoder,
    quayCraneSpreaderEventDecoder,
    terminalTruckStatusEventDecoder,
    yardWorkInstructionEventDecoder,
} from '@/types';
import { andonEventDecoder } from '@/types/andons';
import { craneTriggerWarningUpdatedEventDecoder } from '@/types/craneActivity';
import { delayCodeEventDecoder } from '@/types/delayCodes';

export type Disconnect = () => void;
class RetriableError extends Error {}
class FatalError extends Error {}

const reconnectingEventSource = (
    url: string,
    handler: (message: EventSourceMessage) => void,
    updateToken: Auth['updateToken'],
    setWarningMetrics: ConnectionWarningStore['setWarningMetrics'],
): Disconnect => {
    const sseLogsEnabled = Cookies.get(CookieNames.SseLogs) === 'true';
    const { receivedMessage, sseConnectionIsClosed, sseConnectionIsOpen } = setWarningMetrics;
    const abortController = new AbortController();
    let shouldCloseConnection = false;
    let isConnected = false;

    async function connect() {
        return fetchEventSource(url, {
            headersGenerator: async () => {
                const accessToken = await updateToken();
                return {
                    Authorization: `Bearer ${accessToken}`,
                };
            },
            signal: abortController.signal,
            openWhenHidden: true, // keep connection open when eg tabbed away, else fetchEventSource tries to connect again with old token when tab becomes active again
            async onopen(response: Response) {
                sseConnectionIsOpen();
                isConnected = true;
                if (shouldCloseConnection) {
                    abortController.abort(
                        'Aborted before openened (happens mainly in dev mode because of double rendering)',
                    );
                    return;
                } else if (
                    response.ok &&
                    response.headers.get('content-type')?.startsWith(EventStreamContentType)
                ) {
                    if (sseLogsEnabled) {
                        console.info(`✅ Connected to SSE [${url}] @ ${new Date().toISOString()}`);
                    }
                    return; // everything's good
                } else if (
                    response.status >= 400 &&
                    response.status < 500 &&
                    response.status !== 429
                ) {
                    if (sseLogsEnabled) {
                        console.error(
                            `🚫 Could not connect to SSE [${url}], response status ${
                                response.status
                            } @ ${new Date().toISOString()}`,
                        );
                    }
                    sseConnectionIsClosed();
                    // client-side errors are usually non-retriable:
                    throw new FatalError();
                } else {
                    throw new RetriableError();
                }
            },
            onerror(err: unknown) {
                if (err instanceof FatalError) {
                    sseConnectionIsClosed();
                    throw err; // rethrow to stop the operation
                } else {
                    if (sseLogsEnabled) {
                        console.info(
                            `🚫 Reconnecting: SSE connection errored [${url}] @ ${new Date().toISOString()}`,
                            err,
                        );
                    }

                    // do nothing to automatically retry. You can also
                    // return a specific retry interval here.
                }
            },
            onclose() {
                sseConnectionIsClosed();
                throw new RetriableError('Server closed connection, retrying');
            },
            onmessage(msg) {
                receivedMessage();
                handler(msg);
            },
        });
    }

    connect()
        .then(() => {
            if (sseLogsEnabled) {
                console.info(
                    `🔒 Closed SSE connection [${url}] by DPOS @ ${new Date().toISOString()}`,
                );
            }
        })
        .catch(reason => {
            console.error('SSE connection error', reason);
        });

    const disconnect = () => {
        if (sseLogsEnabled) {
            console.info(
                `🔒 Closing SSE connection [${url}] by DPOS @ ${new Date().toISOString()}`,
            );
        }
        if (isConnected) {
            abortController.abort();
        } else {
            shouldCloseConnection = true; // to make sure we also abort when disconnect was called before the request was openened (else abort signal is ignored)
        }
    };

    return () => {
        disconnect();
    };
};

const keepAliveDecoder = Codec.interface({
    id: exactly('keep-alive'),
});

type KeepAliveEvent = GetType<typeof keepAliveDecoder>;
const isKeepAlive = (data: unknown | KeepAliveEvent): data is KeepAliveEvent => {
    return (
        data !== null &&
        typeof data === 'object' &&
        (data as unknown as KeepAliveEvent).id === 'keep-alive'
    );
};

export const serverSentEventDecoder = oneOf([
    terminalTruckPositionsChangedDecoder,
    chePositionsChangedDecoder,
    quayCraneSpreaderEventDecoder,
    delayCodeEventDecoder,
    moveInstructionV2EventDecoder,
    terminalTruckStatusEventDecoder,
    andonEventDecoder,
    yardWorkInstructionEventDecoder,
    craneTriggerWarningUpdatedEventDecoder,
    keepAliveDecoder,
]);
type Event = GetType<typeof serverSentEventDecoder>;

const messagesCache: Event[] = [];

export const useSubscribeHook = (decodedGpsSignalStaleAfterMinutes: number) => {
    const setWarningMetrics = useConnectionWarningStore(store => store.setWarningMetrics);
    const sseLogsEnabled = Cookies.get(CookieNames.SseLogs) === 'true';
    const auth = useAuth();
    const truckPositionsStore = useTruckPositionsStore(
        useShallow(state => {
            return {
                updateTruckPositions: state.updateTruckPositions,
                setQuayCraneStatusInfoPerQuayCrane: state.setQuayCraneStatusInfoPerQuayCrane,
            };
        }),
    );
    const setCraneActivityWarning = useCraneActivityStore(useShallow(store => store.setWarning));
    const chePositionsStore = useChePositionsStore(
        useShallow(store => {
            return {
                updateChePositions: store.updateChePositions,
            };
        }),
    );
    const setTrucksPerQuayCrane = useTruckStatusStore(
        useShallow(store => store.setTrucksPerQuayCrane),
    );
    const setYardWorkInstruction = useYardStore(useShallow(store => store.setYardWorkInstruction));
    const setDelayCodes = useDelayCodeStore(
        useShallow(store => {
            return store.setDelayCodes;
        }),
    );

    const updateAndons = useAndonsStore(
        useShallow(store => {
            return store.updateAndons;
        }),
    );
    const setDataPerQuayCrane = useQuayCraneStore(
        useShallow(store => {
            return store.setDataPerQuayCrane;
        }),
    );

    const eventHandler = useCallback((message: EventSourceMessage) => {
        Either.encase(() => JSON.parse(message.data))
            .mapLeft(() => 'Failed to parse payload')
            .chain(data => {
                return serverSentEventDecoder.decode(data);
            })
            .caseOf({
                Right: (message: Event) => {
                    messagesCache.push(message);
                },
                Left: err => {
                    setWarningMetrics.sseMessageDecoderFailed();
                    if (sseLogsEnabled) {
                        console.info(`Skipping message for reason [${err}`, message.data);
                    }
                },
            });
    }, []);

    useEffect(() => {
        const flushInterval = setInterval(() => {
            messagesCache.forEach(message => {
                if (isKeepAlive(message)) {
                    return;
                }

                switch (message.event) {
                    case 'YARD_MOVE_INSTRUCTION_UPDATED':
                        setYardWorkInstruction(message.data);
                        return;
                    case 'TERMINAL_TRUCK_POSITIONS_CHANGED':
                        truckPositionsStore.updateTruckPositions(
                            decodedGpsSignalStaleAfterMinutes,
                            message.data.positions,
                        );
                        return;
                    case 'CONTAINER_HANDLING_EQUIPMENT_POSITIONS_CHANGED':
                        chePositionsStore.updateChePositions(
                            decodedGpsSignalStaleAfterMinutes,
                            message.data.positions,
                        );
                        return;
                    case 'QUAY_CRANE_SPREADER_UPDATED':
                        truckPositionsStore.setQuayCraneStatusInfoPerQuayCrane(message.data);
                        return;
                    case 'QUAY_CRANE_DELAY_CODES':
                        setDelayCodes(message.data);
                        return;
                    case 'TRUCK_STATUS_UPDATED':
                        setTrucksPerQuayCrane(message.data.quayCraneName, message.data.trucks);
                        return;
                    case 'MOVE_INSTRUCTION_UPDATED_V2':
                        setDataPerQuayCrane({
                            quayCraneName: message.data.quayCraneName,
                            workQueues: message.data.workQueues,
                            flowStatus: message.data.flowStatus,
                            isLongCrane: message.data.isLongCrane,
                            spreaderAction: message.data.spreaderAction,
                            pullPositionDescription: message.data.pullPositionDescription,
                            messageCreated: message.data.messageCreated,
                            consideredCompletedTruckNames:
                                message.data.consideredCompletedTruckNames,
                        });
                        return;
                    case 'ANDON_STATE':
                        updateAndons(message.data);
                        return;
                    case 'CRANE_TRIGGER_WARNING_UPDATED':
                        setCraneActivityWarning(message.data);
                        return;
                    default:
                        // eslint-disable-next-line
                        const e: never = message;
                }
            });
            messagesCache.length = 0;
        }, 200);

        return () => {
            clearInterval(flushInterval);
        };
    }, []);

    // Only init & connect once
    const eventSourceDisconnect = useRef<Disconnect>();
    useEffect(() => {
        if (eventSourceDisconnect.current === undefined && eventHandler) {
            eventSourceDisconnect.current = reconnectingEventSource(
                routes.api.terminalSseEndpoint(),
                eventHandler,
                auth.updateToken,
                setWarningMetrics,
            );
        }

        return () => {
            eventSourceDisconnect.current && eventSourceDisconnect.current();
            eventSourceDisconnect.current = undefined;
        };
    }, [auth.updateToken, eventHandler]);
};
