import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { FlowStatus, YardWorkInstructions } from '@/types';

export const yardStatus = (yardInstructions: YardWorkInstructions): FlowStatus => {
    const pulls = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.PULL,
    ).length;
    const standby = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.STANDBY,
    ).length;
    const uc = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.UNDER_CRANE,
    ).length;
    return yardStatusForAmounts(pulls, standby, uc);
};

export const yardStatusForYardWork = (
    yardWork: { flowPosition: FlowPositionEnum }[],
): FlowStatus => {
    const pulls = yardWork.filter(job => job.flowPosition === FlowPositionEnum.PULL).length;
    const standby = yardWork.filter(job => job.flowPosition === FlowPositionEnum.STANDBY).length;
    const uc = yardWork.filter(job => job.flowPosition === FlowPositionEnum.UNDER_CRANE).length;

    return yardStatusForAmounts(pulls, standby, uc);
};

const yardStatusForAmounts = (pull: number, standby: number, underCrane: number): FlowStatus => {
    if (underCrane === 0) {
        return FlowStatus.Stopped;
    }
    if (pull === 0) {
        return FlowStatus.Risk;
    }
    if (pull >= 3) {
        return FlowStatus.OverDeployed;
    }
    return FlowStatus.Active;
};
