import { Button } from '@maersk-global/apmt-react-components';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { Maybe } from 'purify-ts';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNetworkState } from 'react-use';
import { useShallow } from 'zustand/shallow';

import { Notification } from '@/components/atoms/Notification/Notification';
import { FlowDetailMapToggle } from '@/components/molecules/FlowDetailMapToggle/FlowDetailMapToggle';
import { FlowDetailDrawer } from '@/components/organisms/FlowDetailDrawer/FlowDetailDrawer';
import { FlowVisualisation } from '@/components/organisms/FlowVisualisation/FlowVisualisation';
import { MapComponent } from '@/components/organisms/Map/Map';
import { NoWorkQueue } from '@/components/organisms/NoWorkQueue/NoWorkQueue';
import { FlowDetailMapOptions } from '@/constants/enums';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { filterAllAndonsForQuayCrane } from '@/hooks/useFilterAllAndonsForQuayCrane';
import { useGetWorkQueuesPerQuayCrane } from '@/hooks/useGetWorkQueuesPerQuayCrane';
import { useStackingEquipment } from '@/hooks/useStackingEquipment';
import { routes } from '@/routes/routes';
import { useAndonsStore, useQuayCraneStore } from '@/store';
import { useCraneActivityStore } from '@/store/craneActivity';
import { useDelayCodeStore } from '@/store/delayCodesStore';
import { useTerminalStore } from '@/store/terminalStore';
import { cn } from '@/utils/cn';
import { toggleFeature } from '@/utils/toggleFeatureFlag';

const DATA_REFRESH_INTERVAL = 10000;

export const FlowDetail = () => {
    const { quayCraneName, terminalId } = useTerminalStore(
        useShallow(state => ({
            quayCraneName: state.quayCraneName,
            terminalId: state.terminalId,
        })),
    );

    const navigate = useNavigate();
    const { online } = useNetworkState();
    const { view: mapDetailQueryString = FlowDetailMapOptions.MAP, locale } = useParams();
    const delayCodes = useDelayCodeStore(useShallow(store => store.delayCodes));
    const delayCodesForQuayCrane = Object.values(delayCodes).find(
        d => d.quayCraneId === quayCraneName,
    );
    const workQueuesPerQuayCrane = useGetWorkQueuesPerQuayCrane(quayCraneName);
    const consideredCompletedTruckNames =
        useQuayCraneStore(
            useShallow(
                store => store.workQueuesPerQuayCrane[quayCraneName]?.consideredCompletedTruckNames,
            ),
        ) ?? [];

    const warnings = useCraneActivityStore(useShallow(store => store.warnings));
    const craneHasWarning = Maybe.fromNullable(warnings[quayCraneName])
        .map(warning => warning.active)
        .orDefault(false);

    // All stacking equipment for this QC
    const { data: stackingEquipment = [] } = useStackingEquipment({
        terminalId,
        quayCraneName,
        pollInterval: DATA_REFRESH_INTERVAL,
    });

    const andons = useAndonsStore(useShallow(store => store.andons));
    // Get equipment & andons for this quayCrane
    const allAndonsForThisQuayCrane = filterAllAndonsForQuayCrane(
        andons,
        quayCraneName,
        stackingEquipment,
    );

    const setMapDetailUrlParam = (type: FlowDetailMapOptions) => {
        navigate(routes.flowDetail(terminalId, quayCraneName, type, locale));
    };

    const flagName = FeatureFlagV1.NO_AD_TRIGGERS;

    const { data } = useFeatureFlag({ cheType: 'QUAY_CRANE', flag: flagName });
    const tosOnlyCraneTriggersEnabled = data?.includes(quayCraneName) ?? false;
    const toggleTosOnlyTriggers = async (checked: boolean) =>
        toggleFeature(
            terminalId,
            quayCraneName,
            { cheType: 'QUAY_CRANE', flag: flagName },
            checked,
        );
    const handleFlowDetailToggleClicked = (type: FlowDetailMapOptions) => {
        setMapDetailUrlParam(type);
    };

    const activePanelType =
        mapDetailQueryString === FlowDetailMapOptions.MAP
            ? FlowDetailMapOptions.MAP
            : FlowDetailMapOptions.VISUALISATION;

    useEffect(() => {
        if (!online) {
            setMapDetailUrlParam(FlowDetailMapOptions.VISUALISATION);
        }
    }, [online]);

    if (quayCraneName === undefined) {
        navigate(routes.flowOverview(terminalId, locale));
        return null;
    }

    if (!workQueuesPerQuayCrane || workQueuesPerQuayCrane.workQueues.length === 0) {
        return (
            <div className="mx-auto h-full">
                <NoWorkQueue terminalId={terminalId} quayCraneName={quayCraneName} />
            </div>
        );
    }
    const showTriggerWarning = tosOnlyCraneTriggersEnabled || craneHasWarning;
    return (
        <div className="flex size-full">
            <div className="relative w-8/12 xl:w-7/12 2xl:w-8/12">
                <div className="absolute z-40 flex w-full items-center justify-center px-56 pt-4 ">
                    <div className="absolute right-4 top-[17px]">
                        <FlowDetailMapToggle
                            active={activePanelType}
                            onClick={(type: FlowDetailMapOptions) => {
                                handleFlowDetailToggleClicked(type);
                            }}
                        />
                    </div>
                </div>
                <div className="absolute z-40 mt-12 flex w-full justify-start px-6 pt-4">
                    {showTriggerWarning && (
                        <Notification
                            body={
                                <div className="flex flex-row items-center">
                                    {!tosOnlyCraneTriggersEnabled
                                        ? `It looks like ${quayCraneName} is not sending triggers`
                                        : `TOS-Triggers are enabled`}
                                    <Button
                                        className={cn('underline', {
                                            'text-feedback-warning-text':
                                                !tosOnlyCraneTriggersEnabled,
                                            'text-feedback-info-text': tosOnlyCraneTriggersEnabled,
                                        })}
                                        variant="link"
                                        onClick={async () => {
                                            void toggleTosOnlyTriggers(
                                                !tosOnlyCraneTriggersEnabled,
                                            );
                                        }}
                                    >
                                        {!tosOnlyCraneTriggersEnabled
                                            ? `Switch to TOS-Triggers`
                                            : `Switch back to crane-triggers`}
                                    </Button>
                                </div>
                            }
                            appearance={!tosOnlyCraneTriggersEnabled ? `warning` : `info`}
                            className="items-center"
                        />
                    )}
                </div>

                {activePanelType === FlowDetailMapOptions.MAP ? (
                    <MapComponent className="h-screen-min-navigation" />
                ) : null}

                {activePanelType === FlowDetailMapOptions.VISUALISATION ? (
                    <FlowVisualisation
                        flowStatus={workQueuesPerQuayCrane.flowStatus}
                        workQueues={workQueuesPerQuayCrane.workQueues}
                        andons={allAndonsForThisQuayCrane}
                        consideredCompletedTruckNames={consideredCompletedTruckNames}
                        stackingEquipment={stackingEquipment}
                    />
                ) : null}
            </div>
            <div className="ml-auto w-4/12 min-w-[477px] xl:w-5/12 2xl:w-4/12">
                <FlowDetailDrawer
                    flowStatus={workQueuesPerQuayCrane.flowStatus}
                    workQueues={workQueuesPerQuayCrane.workQueues}
                    consideredCompletedTruckNames={consideredCompletedTruckNames}
                    delayCodes={delayCodesForQuayCrane?.delayCodes ?? []}
                    andons={allAndonsForThisQuayCrane}
                    quayCraneName={quayCraneName}
                    tosOnlyCraneTriggersEnabled={tosOnlyCraneTriggersEnabled}
                    toggleTosOnlyTriggers={toggleTosOnlyTriggers}
                />
            </div>
        </div>
    );
};
