import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { AndonsLabel } from '@/components/atoms/AndonsLabel/AndonsLabel';
import { FlowStatusLabel } from '@/components/atoms/FlowStatusLabel/FlowStatusLabel';
import { Label } from '@/components/atoms/Label/Label';
import { YardWorkForVisualisation } from '@/components/molecules/YardEquipment/YardEquipmentCompact';
import { Card } from '@/components/organisms/Cards/Card';
import { FlowVisualisationSmall } from '@/components/organisms/FlowVisualisation/FlowVisualisationSmall';
import { WorkQueueKind, WorkQueueType } from '@/constants/enums';
import { filterAllAndonsForQuayCrane } from '@/hooks/useFilterAllAndonsForQuayCrane';
import { routes } from '@/routes/routes';
import { useTerminalStore } from '@/store/terminalStore';
import { FlowStatus, WorkQueues, YardWorkInstructions } from '@/types';
import { Andons } from '@/types/andons';
import { cn } from '@/utils/cn';
import { filterCompletedTrucks } from '@/utils/filterCompletedTrucks';

export const QuayCraneCard = ({
    andons,
    workQueues,
    quayCraneName,
    isLongCrane = false,
    yardWorkInstructions,
    flowStatus,
    consideredCompletedTruckNames,
}: {
    andons: Andons;
    workQueues: WorkQueues[];
    quayCraneName: string;
    flowStatus: FlowStatus;
    consideredCompletedTruckNames: string[];
    isLongCrane?: boolean;
    yardWorkInstructions: Record<string, YardWorkInstructions>;
}) => {
    const { t } = useTranslation();
    const workQueuesWithoutCompletedTrucks = filterCompletedTrucks(
        workQueues,
        consideredCompletedTruckNames,
    );
    const instructionsForThisQuayCrane = workQueuesWithoutCompletedTrucks
        .map(wq => wq.instructions)
        .flat(1);

    const isStopped = flowStatus === FlowStatus.Stopped;
    const isRisk = flowStatus === FlowStatus.Risk;
    const isOverDeployed = flowStatus === FlowStatus.OverDeployed;
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));
    const yardWorkForThisQC: YardWorkForVisualisation = {
        yardStackingEquipment: Object.values(yardWorkInstructions)
            .filter(ywi => {
                return ywi.jobs.some(j => j.type === 'InternalJob' && j.serving === quayCraneName);
            })
            .map(ywi => {
                return {
                    cheId: ywi.che,
                    jobs: ywi.jobs,
                };
            }),
    };
    const chesForThisQuayCrane = yardWorkForThisQC.yardStackingEquipment.map(work => ({
        name: work.cheId,
    }));
    const allAndonsForThisQuayCrane = filterAllAndonsForQuayCrane(
        andons,
        quayCraneName,
        chesForThisQuayCrane,
    );

    const numberOfAndons = allAndonsForThisQuayCrane.length;
    const activeWorkQueue = workQueues[0];
    const loadModeLabel =
        activeWorkQueue?.kind === WorkQueueKind.DUALCYCLE
            ? `${t('labels.dualCycling')}`
            : activeWorkQueue?.loadType === WorkQueueType.DISCHARGE
            ? `${t('labels.discharging')}`
            : `${t('labels.loading')}: ${activeWorkQueue?.loadMode?.toLowerCase()}`;

    const workQueueName =
        activeWorkQueue?.kind === WorkQueueKind.DUALCYCLE
            ? activeWorkQueue.name.join(' & ')
            : activeWorkQueue?.name;

    return (
        <>
            <Card
                className={cn(
                    'group relative h-max gap-2 border-[6px] pr-8 shadow-card hover:shadow-card-hover',
                    {
                        'border-transparent': !isStopped && !isRisk && !isOverDeployed,
                        'border-feedback-danger': isStopped,
                        'border-feedback-warning': isRisk,
                        'border-blue-600': isOverDeployed,
                    },
                )}
            >
                <Link
                    to={routes.flowDetail(terminalId, quayCraneName)}
                    className="mds-font--display-1 -mr-4 block after:absolute after:left-0 after:top-0 after:z-30 after:size-full"
                >
                    <div className="flex items-center gap-2">
                        <span
                            className="border-b-2 border-transparent group-hover:border-black"
                            title={workQueueName}
                        >
                            {quayCraneName}
                        </span>
                        {activeWorkQueue?.loadMode ? (
                            <Label variant="default" shape="pill">
                                {loadModeLabel}
                            </Label>
                        ) : null}
                        {isLongCrane ? (
                            <Label variant="info" weight="light" shape="pill">
                                {t('labels.long')}
                            </Label>
                        ) : null}
                    </div>
                </Link>

                <FlowVisualisationSmall
                    flowStatus={flowStatus}
                    quayCraneName={quayCraneName}
                    instructions={instructionsForThisQuayCrane}
                    andons={allAndonsForThisQuayCrane}
                    yardWork={yardWorkForThisQC}
                />

                {(isStopped || isRisk || isOverDeployed) && (
                    <FlowStatusLabel
                        flowStatus={flowStatus}
                        shape="block"
                        fit="large"
                        className="absolute right-[-6px] top-[-6px] z-10 rounded-bl-[4px] rounded-tr-[4px]"
                    />
                )}
                {numberOfAndons > 0 && <AndonsLabel andons={numberOfAndons} />}
            </Card>
        </>
    );
};
