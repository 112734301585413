import {
    AndonV1,
    WorkQueueV1,
    MoveStatus as MoveStatusEnum,
    MoveKind as MoveKindEnum,
    cheTypeEnum,
    FlowPositionEnum,
    TerminalTruckLocationEnum,
    YardStackingEquipmentTypeEnum,
} from '@maersk-global/digital-pull-operating-system-spec';

export const connectionStatus = ['CONNECTED', 'DISCONNECTED', 'NOT_ASKED'] as const;
export type SseConnectionStatusOption = (typeof connectionStatus)[number];

export type CheType = cheTypeEnum;
export const CheType = cheTypeEnum;

export type AndonTypeOption = AndonV1.andonType;
export const AndonTypeOption = AndonV1.andonType;

export type WorkQueueStatus = WorkQueueV1.status;
export const WorkQueueStatus = WorkQueueV1.status;

export type WorkQueueType = WorkQueueV1.type;
export const WorkQueueType = WorkQueueV1.type;

export type WorkQueueMode = WorkQueueV1.loadMode;
export const WorkQueueMode = WorkQueueV1.loadMode;

export type FlowPositionName = FlowPositionEnum;
export const FlowPositionName = FlowPositionEnum;

export type MoveStatus = MoveStatusEnum;
export const MoveStatus = MoveStatusEnum;

export type MoveKind = MoveKindEnum;
export const MoveKind = MoveKindEnum;

export type TerminalTruckLocation = TerminalTruckLocationEnum;
export const TerminalTruckLocation = TerminalTruckLocationEnum;

export type YardStackingEquipmentType = YardStackingEquipmentTypeEnum;
export const YardStackingEquipmentType = YardStackingEquipmentTypeEnum;

export enum TruckMapShapes {
    CIRCLE = 'CIRCLE',
    SQUARE = 'SQUARE',
    HEXAGON = 'HEXAGON',
    DIAMOND = 'DIAMOND',
}

export enum VisualAppearance {
    DONE = 'DONE',
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
    IDLE = 'IDLE',
    IN_PROGRESS = 'IN-PROGRESS',
}

export enum TruckMapColors {
    BLUE = 'text-blue-800',
    LIGHT_BLUE = 'text-blue-500',
    RED = 'text-red-800',
    GREEN = 'text-green-500',
    GRAY = 'text-gray-500',
    LIGHT_GRAY = 'text-gray-200',
    ORANGE = 'text-orange-500',
    AMBER = 'text-amber-400',
}

export enum FlowDetailMapOptions {
    // Lowercase because it's used to match urls
    MAP = 'map',
    VISUALISATION = 'visualisation',
}

export enum RequestStatus {
    NOT_ASKED = 'NOTASKED',
    ERROR = 'ERROR',
}

export enum FlowVisualisationVariant {
    SMALL = 'SMALL',
    LARGE = 'LARGE',
}

export enum SpreaderAction {
    // Lowercase because the value comes from the backend
    LOCK = 'lock',
    UNLOCK = 'unlock',
}

export enum WorkQueueKind {
    SINGLECYCLE = 'SingleCycle',
    DUALCYCLE = 'DualCycle',
}

export enum QueryStringKeys {
    QUAYCRANES = 'quayCranes',
    LONGCRANES = 'longCranes',
}
export type QueryStringKeyValues = (typeof QueryStringKeys)[keyof typeof QueryStringKeys];
