import { Table } from '@maersk-global/apmt-react-components';
import {
    SvgExclamationTriangle,
    SvgExclamationTriangleSolid,
} from '@maersk-global/apmt-react-icons';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { FlowStatusLabel } from '@/components/atoms/FlowStatusLabel/FlowStatusLabel';
import { Label } from '@/components/atoms/Label/Label';
import { TruckBubbles } from '@/components/atoms/TruckBubbles/TruckBubble';
import { AndonTypeOption, FlowPositionName } from '@/constants/enums';
import { routes } from '@/routes/routes';
import { useAndonsStore, useTerminalStore } from '@/store';
import { AssignedPosition, FlowStatus, YardWorkInstructions } from '@/types';
import { AndonEvent } from '@/types/andons';
import { capitaliseFirstCharacter } from '@/utils/capitaliseFirstCharacter';
import { cn } from '@/utils/cn';
import { getAndonsForEquipment } from '@/utils/getAndonsForEquipment';
import { yardStatus } from '@/utils/getFlowStatus';

type YardEquipmentTableProps = {
    data: YardWorkInstructions[];
    onRowClicked: (cheId: string) => void;
};
type YardWorkTable = {
    che: string;
    serving: { quayCraneName: string; andon: boolean }[];
    status: FlowStatus;
    jobs: YardWorkInstructions['jobs'];
    andons: AndonEvent[];
};
export const YardEquipmentTable = ({ data, onRowClicked }: YardEquipmentTableProps) => {
    const { t } = useTranslation();
    const { equipmentName } = useParams();
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));
    const columnHelper = createColumnHelper<YardWorkTable>();
    const andons = useAndonsStore(useShallow(store => store.andons));

    const defaultColumns = useMemo(
        () => [
            columnHelper.accessor('che', {
                enableSorting: true,
                header: () => <>YSE</>,
                cell: data => <>{data.row.original.che}</>,
            }),
            columnHelper.accessor('status', {
                enableSorting: true,
                header: info => capitaliseFirstCharacter(info.column.id),
                cell: data => {
                    const flowStatus = data.getValue();
                    const isActive = flowStatus === FlowStatus.Active;
                    const isStopped = flowStatus === FlowStatus.Stopped;

                    if (isActive) {
                        return (
                            <Label
                                variant="default"
                                weight="light"
                                fit="small"
                                shape="pill"
                                className="mt-[3px] inline-flex"
                            >
                                {t('labels.flowStatus.good')}
                            </Label>
                        );
                    }

                    return (
                        <FlowStatusLabel
                            flowStatus={flowStatus}
                            weight={isStopped ? 'dark' : 'light'}
                            fit="small"
                            shape="pill"
                            showIcon={isStopped}
                            className={cn('mt-[3px] inline-flex', {
                                'text-white': isStopped,
                            })}
                        />
                    );
                },
            }),
            columnHelper.accessor('serving', {
                enableSorting: true,
                header: info => capitaliseFirstCharacter(info.column.id),
                cell: data => {
                    if (data.row.original.serving.length === 0) {
                        return <>-</>;
                    } else {
                        return (
                            <div className="flex items-center gap-2">
                                {data.row.original.serving.map(({ quayCraneName, andon }) => {
                                    if (andon) {
                                        return (
                                            <Link
                                                key={quayCraneName}
                                                to={routes.flowDetail(terminalId, quayCraneName)}
                                                className="flex items-center text-feedback-danger hover:underline"
                                            >
                                                <SvgExclamationTriangleSolid className="mr-1 size-6" />
                                                {quayCraneName}
                                            </Link>
                                        );
                                    } else {
                                        return (
                                            <Link
                                                key={quayCraneName}
                                                to={routes.flowDetail(terminalId, quayCraneName)}
                                                className="text-blue-600 hover:underline"
                                            >
                                                {quayCraneName}
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                        );
                    }
                },
            }),
            columnHelper.display({
                id: 'pull',
                header: () => <span>Pull</span>,
                cell: info => {
                    const trucks = info.row.original.jobs.filter(job => {
                        if (job.type === 'ExternalJob') {
                            return job.assignedPosition === AssignedPosition.STAGING;
                        }
                        return (
                            job.flowPosition === FlowPositionName.PULL &&
                            job.type !== 'RTGHouseKeepingJob'
                        );
                    });

                    return <TruckBubbles position={FlowPositionName.PULL} trucks={trucks || []} />;
                },
            }),
            columnHelper.display({
                id: 'standby',
                header: () => <span>Standby</span>,
                cell: info => {
                    const trucks = info.row.original.jobs.filter(
                        job =>
                            job.type !== 'ExternalJob' &&
                            job.flowPosition === FlowPositionName.STANDBY,
                    );

                    return (
                        <TruckBubbles position={FlowPositionName.STANDBY} trucks={trucks || []} />
                    );
                },
            }),
            columnHelper.display({
                id: 'under_equipment',
                header: () => <span>Under CHE</span>,
                cell: info => {
                    const trucks = info.row.original.jobs.filter(job => {
                        if (job.type === 'ExternalJob') {
                            return job.assignedPosition === AssignedPosition.YARD_BLOCK;
                        }

                        return job.flowPosition === FlowPositionName.UNDER_CRANE;
                    });

                    return (
                        <TruckBubbles
                            position={FlowPositionName.UNDER_CRANE}
                            trucks={trucks || []}
                        />
                    );
                },
            }),
            columnHelper.display({
                id: 'andons',
                header: info => capitaliseFirstCharacter(info.column.id),
                cell: info => {
                    const andons = info.row.original.andons;
                    return (
                        <div className="flex items-center gap-2">
                            {andons.map(andon => (
                                <Label
                                    key={andon.andonType}
                                    shape="pill"
                                    weight="dark"
                                    variant="error"
                                    icon={<SvgExclamationTriangle className="text-xl" />}
                                    className="inline-flex"
                                >
                                    {andon.andonType === AndonTypeOption.OPERATIONAL
                                        ? t('labels.andonTypes.operational').toUpperCase()
                                        : t('labels.andonTypes.technical').toUpperCase()}{' '}
                                    {t('labels.andon_one').toLocaleLowerCase()}
                                </Label>
                            ))}
                        </div>
                    );
                },
            }),
        ],
        [],
    );

    const preppedData: YardWorkTable[] = [...data]
        .sort((a, b) => a.che.localeCompare(b.che))
        .map(row => {
            const flowStatus = yardStatus(row);
            const filteredAndons = getAndonsForEquipment(andons, [{ name: row.che }]);
            return {
                jobs: row.jobs.filter(filterDuplicateTruckNames),
                che: row.che,
                serving: row.jobs.reduce((acc: YardWorkTable['serving'], next) => {
                    if (
                        next.type === 'InternalJob' &&
                        !acc.some(({ quayCraneName }) => quayCraneName === next.serving)
                    ) {
                        const andon = andons.some(a => a.cheName === next.serving);
                        return [...acc, { quayCraneName: next.serving, andon }];
                    }
                    return acc;
                }, []),
                status: flowStatus,
                andons: filteredAndons,
                rowClassName:
                    flowStatus === 'STOPPED'
                        ? 'bg-[#FCDDE0] hover:bg-[#FCDDE0]'
                        : 'bg-white hover:bg-gray-100',
            };
        });

    useEffect(() => {
        if (!equipmentName) {
            setSelectedRowId(null);
        }
    }, [equipmentName]);

    return (
        <div className="max-w-7xl">
            <Table<YardWorkTable>
                columns={defaultColumns}
                data={preppedData}
                selectedRowId={selectedRowId}
                onRowClicked={row => {
                    setSelectedRowId(row.id);
                    onRowClicked(row.original.che);
                }}
                defaultVisibility={{
                    block: false,
                }}
            />
        </div>
    );
};
export const filterDuplicateTruckNames = (
    val: YardWorkInstructions['jobs'][number],
    index: number,
    list: YardWorkInstructions['jobs'],
) => {
    if (val.type !== 'InternalJob' && val.type !== 'TruckHouseKeepingJob') {
        return true;
    }
    const keep =
        index ===
        list.findIndex(item => {
            if (item.type === 'InternalJob' || item.type === 'TruckHouseKeepingJob') {
                return item.truckName === val.truckName;
            }
            return false;
        });
    return keep;
};
