import { YardStackingEquipmentTypeEnum } from '@maersk-global/digital-pull-operating-system-spec';
import { Just, Nothing } from 'purify-ts';

import { FlowPositionTruckBubbleContainer } from '@/components/atoms/FlowPositionTruckBubbleContainer/FlowPositionTruckBubbleContainer';
import { FlowStatus, YardWorkInstructions } from '@/types';
import { Andons } from '@/types/andons';
import { cn } from '@/utils/cn';

import { EquipmentIcon } from '../EquipmentIcon/EquipmentIcon';

type YardEquipmentCompactProps = {
    andons: Andons;
    yardWork: YardWorkForVisualisation;
};
export type YardWorkForVisualisation = {
    yardStackingEquipment: {
        cheId: string;
        jobs: YardWorkInstructions['jobs'][number][];
    }[];
};

export const YardEquipmentCompact = ({ andons = [], yardWork }: YardEquipmentCompactProps) => {
    const hasAndon = andons.some(andon =>
        yardWork.yardStackingEquipment.some(yse => yse.cheId === andon.cheName),
    );
    const hasYardWork = yardWork.yardStackingEquipment.length > 0;
    if (!hasYardWork) {
        return null;
    }
    return (
        <div className="-mb-3 flex flex-col items-center">
            <div className="overflow-hidden text-gray-600">
                <EquipmentIcon
                    equipment={{ type: YardStackingEquipmentTypeEnum.RUBBER_TYRED_GANTRY }}
                    iconSize={70}
                    className={cn('-mb-4 -ml-1 -mr-0 -mt-2', {
                        'text-feedback-danger': hasAndon,
                    })}
                />
            </div>
            <FlowPositionTruckBubbleContainer
                hasAndon={hasAndon}
                flowStatus={FlowStatus.Active}
                equipment={Nothing}
                pullAmount="Fits"
                pull={Just(['pull'])}
                standby={Just('standby')}
                under={Just('under')}
            />
        </div>
    );
};
