import { NavigationRailContent as SharedNavigationRailContent } from '@maersk-global/apmt-dpos-common';
import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { useLocation, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { defaultLocale } from '@/constants/locales';
import { useTerminalSettings } from '@/hooks/terminalInfo/TerminalInfoProvider';
import { useAndonsStore } from '@/store/andonsStore';
import { useTerminalStore } from '@/store/terminalStore';

const isDev = process.env.NODE_ENV === 'development';

export const NavigationRailContent = () => {
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));
    const { isGateTerminal } = useTerminalSettings();
    const { locale } = useParams();
    const { pathname } = useLocation();
    const { user } = useAuth();
    const andons = useAndonsStore(useShallow(store => store.andons));
    const numberOfAndons = Object.values(andons).length;

    if (!terminalId) {
        return null;
    }

    return (
        <SharedNavigationRailContent
            activePathname={pathname}
            gateEnabled={isGateTerminal}
            locale={locale || defaultLocale}
            numberOfOpenAndons={numberOfAndons}
            terminalId={terminalId}
            isDev={isDev}
            settingsEnabled={user.isAdmin || user.isNormal}
        />
    );
};
