import {
    SvgPin,
    SvgExclamationTriangle,
    SvgCog,
    SvgRotate,
    SvgVesselFront,
    SvgContainerStackedAlt,
    SvgArrowsLeftRight,
} from '@maersk-global/apmt-react-icons';
import {
    getLinkColor,
    MenuItem,
    NavigationRail,
    NavigationRailDivider,
} from '@maersk-global/apmt-react-layout';
import { NavLink } from 'react-router-dom';
import { useCookie } from 'react-use';

import { SharedCookieNames } from '../../../constants/sharedCookies';
import { isActiveRoute, sharedRoutes, SharedSlugs } from '../../../routes/sharedRoutes';

export interface NavigationRailContentProps {
    activePathname?: string;
    isExternal?: boolean;
    isDev?: boolean;
    locale: string;
    numberOfOpenAndons: number;
    terminalId: string;
    settingsEnabled?: boolean;
    gateEnabled: boolean;
}

export const NavigationRailContent = ({
    activePathname = '',
    isExternal = false,
    isDev = false,
    locale,
    numberOfOpenAndons,
    terminalId,
    settingsEnabled = true,
    gateEnabled,
}: NavigationRailContentProps) => {
    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    const [controlPageEnabled] = useCookie(SharedCookieNames.ControlPage);
    const routes = sharedRoutes(isExternal, isDev);

    const quayCraneNameRegex = /(QC[a-zA-Z]?)[0]*(\d+)/;
    const isFlowChildPage = quayCraneNameRegex.test(activePathname);

    return (
        <NavigationRail>
            <NavLink
                to={routes.flowOverview(terminalId, locale)}
                className={({ isActive }) =>
                    getLinkColor(
                        isActive ||
                            isFlowChildPage ||
                            isActiveRoute(activePathname, SharedSlugs.flowOverview),
                    )
                }
                end={true}
                reloadDocument={isExternal}
            >
                <MenuItem text={yardEnabled ? 'Quay' : 'Flows'}>
                    <SvgVesselFront className="fill-current text-2xl" />
                </MenuItem>
            </NavLink>
            {gateEnabled && (
                <NavLink
                    to={routes.gate.home(locale)}
                    className={({ isActive }) =>
                        getLinkColor(
                            isActive || isActiveRoute(activePathname, SharedSlugs.yardOverview),
                        )
                    }
                    end={true}
                    reloadDocument={!!gateEnabled}
                >
                    <MenuItem text="Gate">
                        <SvgArrowsLeftRight className="fill-current text-2xl" />
                    </MenuItem>
                </NavLink>
            )}
            {yardEnabled && (
                <NavLink
                    to={routes.yardOverview(terminalId, locale)}
                    className={({ isActive }) =>
                        getLinkColor(
                            isActive || isActiveRoute(activePathname, SharedSlugs.yardOverview),
                        )
                    }
                    end={true}
                    reloadDocument={isExternal}
                >
                    <MenuItem text="Yard">
                        <SvgContainerStackedAlt className="fill-current text-2xl" />
                    </MenuItem>
                </NavLink>
            )}
            {controlPageEnabled && (
                <NavLink
                    to={routes.controlOverview(terminalId, locale)}
                    className={({ isActive }) =>
                        getLinkColor(
                            isActive || isActiveRoute(activePathname, SharedSlugs.controlOverview),
                        )
                    }
                    reloadDocument={isExternal}
                >
                    <MenuItem text="Control">
                        <SvgRotate className="fill-current text-2xl" />
                    </MenuItem>
                </NavLink>
            )}
            <NavLink
                to={routes.terminalMap(terminalId, locale)}
                className={({ isActive }) => getLinkColor(isActive)}
                reloadDocument={isExternal}
            >
                <MenuItem text="Map">
                    <SvgPin className="fill-current text-2xl" />
                </MenuItem>
            </NavLink>
            <NavLink
                to={routes.andon(locale)}
                className={({ isActive }) => getLinkColor(isActive)}
                reloadDocument={!isExternal}
            >
                <MenuItem text="Andons">
                    <SvgExclamationTriangle className="fill-current text-2xl" />
                    {numberOfOpenAndons > 0 && (
                        <span className="absolute right-[6px] top-[6px] size-[14px] rounded-full bg-blue-700 text-xxs font-bold text-white">
                            {numberOfOpenAndons}
                        </span>
                    )}
                </MenuItem>
            </NavLink>
            {settingsEnabled && (
                <>
                    <NavigationRailDivider />
                    <NavLink
                        to={routes.settings(terminalId, locale)}
                        className={({ isActive }) =>
                            getLinkColor(
                                isActive || isActiveRoute(activePathname, SharedSlugs.settings),
                            )
                        }
                        reloadDocument={isExternal}
                    >
                        <MenuItem text="Settings">
                            <SvgCog className="fill-current text-2xl" />
                        </MenuItem>
                    </NavLink>
                </>
            )}
        </NavigationRail>
    );
};
