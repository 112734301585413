import {
    array,
    boolean,
    Codec,
    Either,
    EitherAsync,
    GetType,
    intersect,
    Left,
    number,
    optional,
    Right,
    string,
} from 'purify-ts';

import { AUTH_FAILED_HTTP_STATUS_CODES } from '@/constants/auth';
import {
    CACHE_BUSTER_VERSION,
    TERMINAL_STATIC_CONFIG_PATH,
    TERMINAL_CONFIG_PATH,
} from '@/constants/settings';
import { coordinateDecoder } from '@/types';

const rectangleCoordinates = Codec.interface({
    topLeft: coordinateDecoder,
    topRight: coordinateDecoder,
    bottomRight: coordinateDecoder,
    bottomLeft: coordinateDecoder,
});

export type RectangleCoordinates = GetType<typeof rectangleCoordinates>;

const mapBoundaryDecoder = Codec.interface({
    southWest: coordinateDecoder,
    northEast: coordinateDecoder,
});

export type MapBoundaries = GetType<typeof mapBoundaryDecoder>;

const staticTerminalSettingsDecoder = Codec.interface({
    terminalId: string,
    isGateTerminal: boolean,
    decodedGpsSignalStaleAfterMinutes: number,
    useKeycloak: boolean,
    keycloakUrl: optional(string),
});

export type StaticTerminalSettings = GetType<typeof staticTerminalSettingsDecoder>;

const terminalMapSettingsDecoder = Codec.interface({
    mapCenter: coordinateDecoder,
    mapBearing: number,
    mapMinZoom: number,
    mapMaxZoom: number,
    mapBoundsRadiusInMeters: number,
    mapTerminalImageCoordinates: rectangleCoordinates,
    mapFocusArea: array(coordinateDecoder),
});

export type TerminalMapSettings = GetType<typeof terminalMapSettingsDecoder>;

const allTerminalSettings = intersect(staticTerminalSettingsDecoder, terminalMapSettingsDecoder);

export type TerminalSettings = GetType<typeof allTerminalSettings>;
export type TerminalSettingsError =
    | { type: 'APIError'; cause: 'AuthFailed' | 'NetworkError' | 'Other'; message?: string }
    | { type: 'DecodeError'; message: string };

const retrieveStaticTerminalSettings = async (): Promise<
    Either<TerminalSettingsError, unknown>
> => {
    try {
        const staticConfigUrl = `${TERMINAL_STATIC_CONFIG_PATH}/config.json`;
        const response = await fetch(staticConfigUrl);
        if (response.ok) {
            const staticTerminalSettings = await response.json();
            return Right(staticTerminalSettings);
        } else {
            const cause = AUTH_FAILED_HTTP_STATUS_CODES.includes(response.status)
                ? 'AuthFailed'
                : 'Other';
            return Left({
                type: 'APIError',
                cause,
                message: `Failed to load terminal settings (${response.status})`,
            } as const);
        }
    } catch (e) {
        return Left({ type: 'APIError', cause: 'NetworkError', message: `${e}` } as const);
    }
};

const decodeStaticTerminalSettings = (
    fetchResponse: unknown,
): Either<TerminalSettingsError, StaticTerminalSettings> => {
    return staticTerminalSettingsDecoder.decode(fetchResponse).mapLeft(
        cause =>
            ({
                type: 'DecodeError',
                message: CONFIG_IS_DEV
                    ? `Could NOT load terminal config from server: ${cause}`
                    : '',
            } as const),
    );
};

const validateKeycloakSettings = (
    staticTerminalSettings: StaticTerminalSettings,
): Either<TerminalSettingsError, StaticTerminalSettings> => {
    if (staticTerminalSettings.useKeycloak && !staticTerminalSettings.keycloakUrl) {
        return Left({
            type: 'APIError',
            cause: 'Other',
            message: 'Missing keycloakUrl in static terminal settings',
        } as const);
    }
    return Right(staticTerminalSettings);
};

const retrieveTerminalMapSettings = async (
    terminalId: string,
): Promise<Either<TerminalSettingsError, unknown>> => {
    try {
        const configUrl = `${TERMINAL_CONFIG_PATH}/${terminalId.toLowerCase()}.json?${CACHE_BUSTER_VERSION}`;
        const response = await fetch(configUrl);
        if (response.ok) {
            const terminalMapSettings = await response.json();
            return Right(terminalMapSettings);
        } else {
            const cause = AUTH_FAILED_HTTP_STATUS_CODES.includes(response.status)
                ? 'AuthFailed'
                : 'Other';
            return Left({
                type: 'APIError',
                cause,
                message: `Failed to load terminal map settings (${response.status})`,
            } as const);
        }
    } catch (e) {
        return Left({ type: 'APIError', cause: 'NetworkError', message: `${e}` } as const);
    }
};

const decodeTerminalMapSettings = (
    fetchResponse: unknown,
): Either<TerminalSettingsError, TerminalMapSettings> => {
    return terminalMapSettingsDecoder.decode(fetchResponse).mapLeft(
        cause =>
            ({
                type: 'DecodeError',
                message: CONFIG_IS_DEV
                    ? `Could NOT load terminal map settings from server: ${cause}`
                    : '',
            } as const),
    );
};

const TerminalSettingsChain: EitherAsync<TerminalSettingsError, TerminalSettings> =
    EitherAsync.fromPromise(retrieveStaticTerminalSettings)
        .chain(async fetchResponse => decodeStaticTerminalSettings(fetchResponse))
        .chain(async staticTerminalSettings => validateKeycloakSettings(staticTerminalSettings))
        .chain(async staticTerminalSettings =>
            EitherAsync.fromPromise(async () =>
                retrieveTerminalMapSettings(staticTerminalSettings.terminalId),
            )
                .chain(async terminalMapSettings => decodeTerminalMapSettings(terminalMapSettings))
                .map(
                    (terminalMapSettings): TerminalSettings => ({
                        ...staticTerminalSettings,
                        ...terminalMapSettings,
                    }),
                ),
        );

export const getTerminalSettings = async (): Promise<
    Either<TerminalSettingsError, TerminalSettings>
> => TerminalSettingsChain.run();
