import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { FlowPositionName } from '@/constants/enums';
import { AssignedPosition, YardWorkInstructions } from '@/types';

type YardStore = {
    yardWorkInstructions: Record<string, YardWorkInstructions>;
    setYardWorkInstruction: (message: YardWorkInstructions) => void;
};

export const useYardStore = create<YardStore>()(
    devtools(
        set => {
            return {
                setYardWorkInstruction: (instructions: YardWorkInstructions) => {
                    set(previousState => {
                        return {
                            ...previousState,
                            yardWorkInstructions: {
                                ...previousState.yardWorkInstructions,
                                [instructions.che]: instructions,
                            },
                        };
                    });
                },
                yardWorkInstructions: {},
            };
        },
        { name: 'yardStore' },
    ),
);

export type YardWorkPerQuayCrane = {
    [quayCrane: string]: YardWork;
};
export enum ExternalTrickFlowPosition {
    YARD = 'YARD',
    STAGING = 'STAGING',
}
export type YardWork = {
    [che: string]: {
        workQueueId?: string;
        truck: string;
        flowPosition: FlowPositionName | AssignedPosition;
        instructionSentOn: string;
        serving: string;
    }[];
};
export const splitYardWorkPerQuayCrane = (yardInstructions: Record<string, YardWorkInstructions>) =>
    Object.entries(yardInstructions).reduce((acc: YardWorkPerQuayCrane, [che, yard]) => {
        return yard.jobs.reduce((acc, j) => {
            if (j.type !== 'InternalJob') {
                return acc;
            }
            const qc = j.serving;
            const workForThisQC = acc[qc] ?? {};
            const workForThisChe = workForThisQC[che] ?? [];

            return {
                ...acc,
                [qc]: {
                    ...workForThisQC,
                    [che]: [
                        ...workForThisChe,
                        {
                            flowPosition: j.flowPosition,
                            truck: j.truckName,
                            workQueueId: j.workQueueId,
                            instructionSentOn: j.instructionSentOn,
                            serving: j.serving,
                        },
                    ],
                },
            };
        }, acc);
    }, {});

export const filterYardWorkForQuayCrane = (
    yardInstructions: Record<string, YardWorkInstructions>,
    quayCraneName: string,
): YardWork => {
    const allChesWorkingForQuayCrane = Object.values(yardInstructions).filter(work => {
        return work.jobs.some(j => {
            if (j.type !== 'InternalJob') {
                return false;
            }
            return j.serving === quayCraneName;
        });
    });

    return allChesWorkingForQuayCrane.reduce((acc: YardWork, work) => {
        return {
            ...acc,
            [work.che]: work.jobs.map(j => {
                const flowPosition = j.type === 'ExternalJob' ? j.assignedPosition : j.flowPosition;
                const workQueueId = j.type === 'InternalJob' ? j.workQueueId : undefined;

                return {
                    workQueueId,
                    flowPosition,
                    truck: truckNameForJob(j),
                    instructionSentOn: j.instructionSentOn,
                    serving: j.type === 'InternalJob' ? j.serving : '',
                };
            }),
        };
    }, {});
};

const truckNameForJob = (job: YardWorkInstructions['jobs'][number]) => {
    switch (job.type) {
        case 'RTGHouseKeepingJob':
            return 'Yard';
        case 'ExternalJob':
            return job.truckName ?? 'Ext.';
        case 'InternalJob':
            return job.truckName;
        case 'TruckHouseKeepingJob':
            return job.truckName;
    }
};

export const getYardWorkQueues = (yardInstructions: Record<string, YardWorkInstructions>) =>
    Object.values(yardInstructions)
        .map(({ workQueues }) => workQueues)
        .flat(1);
