import { SvgExclamationTriangleSolid } from '@maersk-global/apmt-react-icons';

import { TagContainer } from '@/components/atoms/TagContainer/TagContainer';
import { TagContainerSmall } from '@/components/atoms/TagContainerSmall/TagContainerSmall';
import { Truck } from '@/components/atoms/Truck/Truck';
import { TruckSmall } from '@/components/atoms/TruckSmall/TruckSmall';
import { getTruckAppearance } from '@/components/molecules/FlowPosition/FlowPosition';
import { FlowVisualisationVariant } from '@/constants/enums';
import ApmPictoShipPortCraneIcon from '@/icons/components/QuayCraneIcon';
import { FlowStatus, MoveInstruction } from '@/types';
import { cn } from '@/utils/cn';

type ComponentProps = {
    name: string;
    andon?: boolean;
    trucks: MoveInstruction[];
    variant?: FlowVisualisationVariant;
    flowStatus?: FlowStatus;
};

export const QuayCranePosition = ({
    name,
    andon,
    trucks,
    flowStatus,
    variant = FlowVisualisationVariant.LARGE,
}: ComponentProps) => {
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;
    const isSmallVariant = variant === FlowVisualisationVariant.SMALL;

    return (
        <div
            className={cn('flex h-full flex-col items-center', {
                'relative -top-4': isSmallVariant,
            })}
        >
            {!isSmallVariant ? (
                <span className={cn('relative -bottom-6 flex')}>
                    {andon ? (
                        <SvgExclamationTriangleSolid
                            color="var(--mdt-theme-palette-feedback-danger-dark)"
                            className="relative top-1 -mt-px mr-1 text-xl"
                        />
                    ) : null}
                    <span
                        className={cn('min-h-[24px]', {
                            'mds-font--default--bold': isLargeVariant,
                            'mds-font--x-small': isSmallVariant,
                            'text-feedback-danger': andon,
                        })}
                    >
                        {name}
                    </span>
                </span>
            ) : null}

            <div className="overflow-hidden">
                <ApmPictoShipPortCraneIcon
                    className={cn({
                        '-mx-2': isLargeVariant,
                        'text-gray-600': !andon,
                        'text-feedback-danger': andon,
                    })}
                    size={isLargeVariant ? 102 : 76}
                />
            </div>

            {isSmallVariant ? (
                <div className="mt-[-16px]">
                    <TagContainerSmall flowStatus={flowStatus}>
                        {trucks.map(item => {
                            const { truckId } = item;
                            return <TruckSmall key={truckId} label={truckId} />;
                        })}
                    </TagContainerSmall>
                </div>
            ) : null}

            {isLargeVariant ? (
                <TagContainer className="min-h-singleTag" flowStatus={flowStatus} error={andon}>
                    {trucks.map(item => {
                        const { truckId, instruction, hasReachedFlowPosition } = item;

                        const appearance = getTruckAppearance(instruction, hasReachedFlowPosition);
                        return <Truck key={truckId} label={truckId} appearance={appearance} />;
                    })}
                </TagContainer>
            ) : null}
        </div>
    );
};
